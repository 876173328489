/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fontshop.com
*/

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=a497beeb-a776-439b-a936-6de78f11fa47&fontids=1488908,1488926,1488932,2999028");

@font-face{
  font-family:"Helvetica Neue LT";
  font-style: italic;
  src:url("../fonts/helvetica/1488908/4c68f39a-1d08-4a26-81b3-c424cbe6d712.eot?#iefix");
  src:url("../fonts/helvetica/1488908/4c68f39a-1d08-4a26-81b3-c424cbe6d712.eot?#iefix") format("eot"),url("../fonts/helvetica/1488908/657c2fa4-585b-4761-9e2e-65ab13159323.woff2") format("woff2"),url("../fonts/helvetica/1488908/400869f0-f0d5-49a0-8a11-f4fb4279d125.woff") format("woff"),url("../fonts/helvetica/1488908/14df02b1-01f4-4426-bf7b-ad2f39dc1346.ttf") format("truetype");
}
@font-face{
  font-family:"Helvetica Neue LT";
  font-weight: bold;
  src:url("../fonts/helvetica/1488926/b7066775-c685-4630-b98c-5da67d4981e9.eot?#iefix");
  src:url("../fonts/helvetica/1488926/b7066775-c685-4630-b98c-5da67d4981e9.eot?#iefix") format("eot"),url("../fonts/helvetica/1488926/dd7814bd-8abf-46c5-a4be-db0977de2cca.woff2") format("woff2"),url("../fonts/helvetica/1488926/20ffa4b4-0154-4ca6-800c-468cecbd60f1.woff") format("woff"),url("../fonts/helvetica/1488926/c68e9fac-92b0-47be-853c-1a0e92f68c3d.ttf") format("truetype");
}
@font-face{
  font-family:"Helvetica Neue LT";
  font-weight: bold;
  font-style: italic;
  src:url("../fonts/helvetica/1488932/264fe832-63b5-439f-bacc-51c65507c211.eot?#iefix");
  src:url("../fonts/helvetica/1488932/264fe832-63b5-439f-bacc-51c65507c211.eot?#iefix") format("eot"),url("../fonts/helvetica/1488932/a1ce96fc-e8fa-4118-8a5c-e06d8d094e56.woff2") format("woff2"),url("../fonts/helvetica/1488932/c577fdb4-0e4a-4767-aa14-5510cf2c8fa4.woff") format("woff"),url("../fonts/helvetica/1488932/9bc35aed-b72c-4515-aa1e-42fe888c15bd.ttf") format("truetype");
}
@font-face{
  font-family:"Helvetica Neue LT";
  src:url("../fonts/helvetica/2999028/098ec412-2fe3-4f81-90c5-5d3f9d51bf74.eot?#iefix");
  src:url("../fonts/helvetica/2999028/098ec412-2fe3-4f81-90c5-5d3f9d51bf74.eot?#iefix") format("eot"),url("../fonts/helvetica/2999028/5937650e-76f0-474f-a1f2-c6f3622b18cd.woff2") format("woff2"),url("../fonts/helvetica/2999028/04c5a6b7-0f39-48b7-94cd-af07a74ba891.woff") format("woff"),url("../fonts/helvetica/2999028/71796deb-604a-4392-b9d5-dd587c515c35.ttf") format("truetype");
}
