/*
//Include to add Sprites to your CSS File
@import "sprites/sprite-...";
@include sprites($spritesheet-sprites);
//@include retina-sprites($retina-groups); //Include if "enable_retina_sprites" is true
*/

body{
  padding-top:67px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1400px){
  .container {max-width:1280px;}
}

@media (max-width: 575px){
  .mt-xs-2, .my-xs-2 {
    margin-top: .5rem!important;
  }
}

.wysiwyg{
  h3{
    font-size:1em;
    font-weight:bold;
  }

  h4{
    font-size:1em;
    color:$primary;
  }
  h5{
    font-size:.8em;
    font-weight:bold;
  }
  h6{
    font-size:.75em;
    font-weight:bold;
  }
}

.mt-6,.my-6{
  margin-top:75px;
}

.mb-6,.my-6{
  margin-bottom:75px;
}

.logo{
  width:109px;
  height:39.64px;
}

.btn-outline{
  border:1px solid $primary;
}

.btn-outline-grey{
  border:1px solid #999999;
  color:#999999;
  transition: border-color .3s, color .3s;
  &:hover{
    border:1px solid $primary;
    color:$primary;
  }
}

.bg-black {
  background: #000;
}

.cart-download-icon{
  width:16.75px;
  height: 15.75px;
  margin-top:-5px;
}

.text-bold{
  font-weight:bold;
}

a,
.navbar-dark .navbar-nav .nav-link {
  transition: color $default-animation-duration;
  &:hover{
    text-decoration: none;
  }
}

.site-header{
  a,
  .navbar-dark .navbar-nav .nav-link{
    color:#FFF;

    &:hover{
      text-decoration: none;
      color: $primary;
    }
  }
  .dropdown-menu{
    border-radius:0;

  }

  .navbar-toggler{
    margin:7px 0;
  }
}


.site-content{
  ul{
    list-style-type: none;
    padding: 0;
    margin: 12px 0 24px;

    li{
      padding-left: 1em;
      position:relative;
      margin-bottom:3px;
    }

    li::after {
      content:"";
      position: absolute;
      width: 5px;
      height: 5px;
      left: 0;
      top: .725em;
      transform: translateY(-50%);
      background-color: $primary;
    }
  }
}

.custom-select{
  background-image:none;
}
.site-header{
  .navbar-dark.navbar-expand-lg {

    .navbar-nav .nav-link:hover,
    a:hover {
      &:hover{
        background:transparent;
        color:#ccc;
      }
    }

    .dropdown-menu{
      background:transparent;
      padding:0 0 5px;
      a.dropdown-item{
        color:#FFF;
        padding-left:5px;
        margin-left:-5px;

        &.active,
        &:active{
          background:transparent;
          color:$primary;
        }

        &:hover{
          background:transparent;
          color:$primary;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .navbar-nav {
        .dropdown-menu{
          position: fixed;
          width: 100%;
          top: 67px;
          border: none;
          box-shadow: none;
          padding: 0;
          margin: 0;
          background: #F2F2F2;
        }

        a.dropdown-item{
          width:auto;
          float:left;
          color:#333;
          padding: 19px 17px;

          &:first-child{
            margin-left:0;
            padding-left:0;
          }

          &.active{
            color: $primary;
            background:transparent;
          }

          &:hover{
            color: $primary;
            background:transparent;
          }
        }
      }
    }
  }
}

.section-page-home {
  .section-text {
    .content-title,
    .content-sub-title {
      text-align: center;
    }
  }
}

.main-slider-section{
  position:relative;

  .main-slider {
    text-align: center;

    .slick-list {
      text-align: left;
    }
  }

  ul.slick-dots {
    margin-bottom:0;
    display: inline-block;
    width: auto;
  }

  .main-slider-container{
    position:relative;
    overflow:hidden;
    max-width:1600px;
    width:100%;

    .slide-img {
      position:relative;
      height: 460px;
      background-position: center center;
      background-size: cover;
      display: block;

      &:focus {
        outline: 0;
      }

      .container{
        position: relative;
        height: 100%;


        .slide-overlay{
          position:absolute;
          bottom:0;
          left:-15px;
          padding: 15px 30px 40px;
          right:33%;
          background: rgba(0,0,0,.3);
          color:#FFF;

          .slide-title {
            line-height:4rem;
            font-size: 4rem;
            font-weight: $font-weight-normal;
          }

          .slide-sub-title {
            font-size: 1.3333rem;
          }

          @include media-breakpoint-down(md) {
            .slide-title {
              font-size: 1.8rem;
            }

            .slide-sub-title {
              font-size: 1.2rem;
            }
          }

          @include media-breakpoint-down(sm) {
            left: 0;
            right: 0;
          }
        }

      }

      @include media-breakpoint-down(lg) {
        height: 400px;
      }

      @include media-breakpoint-down(md) {
        height: 250px;

        .slick-next{  right:25px;}
        .slick-prev{  left:25px;}
      }
    }
  }
}

.blog-header{
  margin:0 auto;
  max-width: 1600px;

  .slide-img {
    position:relative;
    height: 460px;
    background-position: center center;
    background-size: cover;

  }
  .container{
    position: relative;
    height: 100%;


    .slide-overlay{
      width:66.6666%;
      padding: 0 30px;
      background: #FFF;
      color:#000;
      transform: translateY(-50%);

      .slide-title {
        margin-top: 20px;
        font-size: 4rem;
        font-weight: $font-weight-normal;
      }

      .slide-sub-title {
        margin-bottom: 20px;
        font-size: 1.3333rem;
      }

      @include media-breakpoint-down(md) {
        .slide-title {
          font-size: 1.8rem;
        }

        .slide-sub-title {
          font-size: 1.2rem;
        }
      }

      @include media-breakpoint-down(sm) {
        left: 0;
        right: 0;
      }
    }

    @include media-breakpoint-down(lg) {
      height: 400px;
    }

    @include media-breakpoint-down(md) {
      height: 250px;

      .slick-next{  right:25px;}
      .slick-prev{  left:25px;}
    }
  }
}

.blog-content {
  margin-top: -100px;
  background: #fff;

  .blog-content-inner {
    background: #fff;
    padding: 0 10px;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  .blog-share {
    margin-top: 150px;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }
}


@include media-breakpoint-down(md) {
  .slick-arrow{
    z-index:10000;
    &:before{
      color:#FFF;
    }
  }
  .slick-next {
    right: 25px;
  }
  .slick-prev {
    left: 25px;
  }
}
.slick-dots{
  ul{
    padding: 0;
    margin: 0;
  }

  li {
    &:after{
      display:none;
    }

    margin: 0 2px;

    button:before {
      position:absolute;
      background-color: #FFF;
      top:5px;
      left:5px;
      width:10px;
      height:10px;
      content: "";
      opacity: 1;
      transform: scale(1);
      transition: background-color $default-animation-duration, transform $default-animation-duration;
    }
    &.slick-active button:before {
      background-color: theme-color("primary");
      transform: scale(2);
    }

    &:hover{
      & button:before{
        transform: scale(1);
        background-color: #DDD;
      }
      &.slick-active button:before {
        transform: scale(2);
        background-color: theme-color("primary");
      }
    }

    &:only-child{
      display:none;
    }
  }
}

.slick-dotted.slick-slider {
  padding-bottom: 0;
  margin-bottom: 0;
}

.slick-dots {
  position:absolute;
  bottom: 10px;
  left:50%;
  transform: translateX(-50%);
}

.slick-slide {
  &:focus {
    outline: 0;
  }
}

.site-footer{

  .logo{
    height:72.24px;
    width:183px;
  }

  .footer-hr{
    width:100%;
    height:10.07px;
    background-image:url('../img/footer-hr.svg');
    background-position: center center;
    background-repeat:no-repeat;
  }

  .footer-grey{
    background: #F2F2F2
  }

  a.dropdown-item{
    color:#333;
    padding: 5px 0 0;
    width:100%;
    white-space:normal;

    &:first-child{
      padding-left:0;
    }

    &.active{
      color: $primary;
      background:transparent;
    }

    &:hover{
      color: $primary;
      background:transparent;
    }
  }

  a.socialmedia-link{
    background-color:#333;
    transition: background-color .3s;
    color:#FFF;
    margin-left:10px;

    font-size:24px;
    padding:2px 5px;

    &:first-child{
      margin-left:0;
    }

    &:hover{
      color:#FFF;
      background-color: $primary;
    }
  }

  .list-inline {
    display: block;
    padding: 0;

    li {
      display: inline-block;
      padding: 0 10px;

      &:first-child{
        padding-left:0;
      }

      &:last-child{
        padding-right:0;
      }

      @include media-breakpoint-down(md) {
        padding-left:0;
        width:100%;
      }
    }
  }

}

.embed-responsive.youtube-video-link {
  cursor: pointer;
  position: relative;

  .preview-img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-position: center center;
    background-size: cover;
  }

  .play-btn {
    display: block;
    width: 120px;
    height: auto;
    margin-left: -60px;
    margin-top: -35px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.cta-box{
  position:relative;
  background-position:center center;
  background-repeat:no-repeat;
  background-size: cover;
}

.overlay-black-50{
  background:rgba(0,0,0,.5);
}

.images-slider{
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.caption{
  font-size:12px;
}

.blog-share{
  clear:both;

  .btn-share{
    margin:0;
    padding:5px 10px;
    font-size:24px;
    text-align:center;
    display:block;
    float:left;
    transition: background-color .3s;
    color:#FFF;
    &.btn-share-fb{background-color:#43619b;}
    &.btn-share-tw{background-color:#29a9e0;}
    &.btn-share-in{background-color:#0077b4;}
    &.btn-share-gp{background-color:#dc4b39;}
    &.btn-share-ml{background-color:#787878;}

    &:hover{
      &.btn-share-fb,
      &.btn-share-tw,
      &.btn-share-in,
      &.btn-share-gp,
      &.btn-share-ml{background-color:$primary;}
    }
  }
}
.blog-categories{
  clear:both;

  .btn-category{
    margin:0 10px 10px 0;
    padding:5px 10px;
    text-align:center;
    display:block;
    float:left;
    border: 2px solid $primary;
    transition: background-color .3s, color .3s;
    color:$primary;

    &:hover{
      color:#FFF;
      background-color:$primary;
    }
  }
}

.blog-entry {
  display:block;
  position:relative;
  width: 100%;
  background-size:cover;
  background-position: center center;
  overflow:hidden;
  margin-bottom: 30px;

  &.blog-entry-1{
    &:before {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  &.blog-entry-2{
    &:before {
      content: "";
      display: block;
      padding-bottom: calc(50% - 15px);
    }
  }

  &.blog-entry-3{
    &:before {
      content: "";
      display: block;
      padding-bottom: calc(200% + 30px);
    }
  }

  &:after{
    content: "";
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:$primary;
    opacity:0;
    transition: opacity $default-animation-duration;
    z-index:50;
  }

  .blog-entry-teaser{
    position:absolute;
    width:100%;
    top:0;
    left:0;
    background:rgba(0,0,0,.3);
    transition: background $default-animation-duration;
    padding:25px;
    color:#FFF;
    z-index:100;

    p {
      font-size: 0.8rem;
    }
  }

  .blog-entry-teaser-date {
    margin-top: 10px;
    font-size: 0.8rem;
  }

  &:hover{
    .blog-entry-teaser{
      background:rgba(0,0,0,0);
    }
    &:after{
      opacity:.75;
    }
  }

  &.teaser-half{
    .blog-entry-teaser{
      width:calc(50% - 15px);
    }
  }

  @include media-breakpoint-down(sm) {
    &.blog-entry-1:before,
    &.blog-entry-2:before,
    &.blog-entry-3:before {
      padding-bottom: calc(50% - 15px);
    }

    .blog-entry-teaser{
      padding:15px;
    }

    &.teaser-half{
      .blog-entry-teaser{
        width:100%;
      }
    }
  }
}

.square{
  display:block;
  position:relative;
  width: 100%;
  &:before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.related-page{
  display:block;
  position:relative;
  width: 100%;
  background-size: cover;
  background-position: center center;
  height:200px;

  &:before{
    content: "";
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:#000;
    opacity:0.3;
    transition: opacity $default-animation-duration;
  }

  &:after{
    content: "";
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:$primary;
    opacity:0;
    transition: opacity $default-animation-duration;
  }

  .related-page-subtitle{
    position:absolute;
    top:0;
    left:0;
    padding:15px 25px;
    color:#FFF;
    z-index:100;
    font-size: 0.8rem;
  }

  .related-page-title{
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    color:#FFF;
    padding:25px;
    z-index:100;
    transition: padding .2s;
  }

  &:hover{
    .related-page-title{
      padding-bottom: 50px;
    }

    &:before{
      opacity:0;
    }
    &:after{
      opacity:.75;
    }
  }
}

.product-category{
  display:block;
  position:relative;
  overflow:hidden;

  .category-image{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-size:cover;
    background-position: center center;
    transform: scale(1);
    transition: transform $default-animation-duration;
  }

  .category-title{
    position:absolute;
    width:100%;
    bottom:0;
    left:0;
    z-index:100;
    color:#FFF;
    background:rgba(0,0,0,.5);


    .readmore-effect{
      color:$primary;
      transition:margin-left $default-animation-duration;
      margin-left:0;
    }
  }

  &:hover{
    .category-image{
      transform:scale(1.1);
    }

    .category-title{
      .readmore-effect{
        margin-left:10px;
      }
    }
  }
}

.box-more-btn {
  position: absolute;
  bottom: 25px;
  left: 25px;
  color: #fff;
  opacity: 0;
  transition: opacity .2s;
  z-index: 100;

  @include media-breakpoint-down(md) {
    left: 15px;
    bottom: 15px;
  }
}

.readmore-element:hover {
  .box-more-btn {
    opacity: 1;
  }
}

.readmore-element {
  .readmore-effect {
    color: $primary;
    margin-left: 0;
    transition: margin-left $default-animation-duration;
  }

  &:hover {
    .readmore-effect {
      margin-left: 10px;
    }
  }
}

.product-container{
  .table{
    th, td{
      padding: .25em;
    }
  }
}


.product-slider-container{
  .product-slider{
    display:none;
    .slick-dots{
      margin:0;
      bottom:-30px;
      li button:before{
        background-color:#000;
      }

      li.slick-active button:before{
        background-color: $primary;
        opacity: 1;
      }
    }

    &.active{
      display:block;
    }

    .product-slider-caption{
      font-size:12px;
      margin-top:10px;
      padding: 0;
    }
  }
}

.product-box{
  display:block;
  color:#000;
  .product-title{
    color:inherit;
  }
  .product-image-container{
    position:relative;
    overflow:hidden;
    img{
      min-width:100%;
      transition:transform .3s;
    }

    .product-variants{
      position:absolute;
      bottom:$default-margin;
      right:$default-margin;
      color:#FFF;
      font-weight:bold;


      display:flex;
      justify-content: flex-end;
      .product-variants-title{
        position:relative;
        overflow:hidden;

        .product-variants-title-inner{
          transition: transform .3s;
          transform:translateX(100%);
          padding:5px 10px;
          background:rgba(0,0,0,0.5);
        }
      }
      .product-variants-sum{
        position:relative;
        background:rgba(0,0,0,0.5);
        padding:5px 10px;
      }
    }
  }

  &:hover{
    color:#000;
    .product-image-container{
      img{
        transform:scale(1.1);
      }
      .product-variants{
        .product-variants-title{
          .product-variants-title-inner {
            transform:translateX(0%);
          }
        }
      }
    }
  }
}

.contact-box{
  background:#F2F2F2;
  height:100%;

  .contact-image{
    position:relative;
    width:100%;
    overflow: hidden;
    padding-top: 100%;
    background-color:#333;
    background-size: cover;
    background-position: center center;

  }

  .contact-title{
    position:absolute;
    bottom:0;
    left:0;
    padding:5px 15px;
    color:#FFF;
  }
}

.social-link{
  margin: 0 5px;
  display:inline-flex;
  width:40px;
  height:40px;
  color:#FFF;


  i{
    font-size:20px;
    color:#FFF;
  }

  &.social-link-xing{
    background-color:#005A5F;
    transition: background-color .3s;
    &:hover{
      background-color: $primary;
    }
  }


  &.social-link-linkedin{
    background-color:#1687B1;
    transition: background-color .3s;
    &:hover{
      background-color: $primary;
    }
  }

  &.social-link-vfc{
    text-align: center;
    border: 1px solid #999999;
    color:#999999;
    padding: 0 30px;
    font-size:12px;
    transition: border-color .3s, color .3s;

    i{
      font-size:12px;
      color:#999999;
      transition: color .3s;
    }

    &:hover{
      color: $primary;
      border-color: $primary;

      i{
        color:$primary;
      }
    }
  }

  &:first-child{
    margin-left:0;
  }
  &:last-child{
    margin-right:0;
  }
}


.btn-inline-filter{
  margin: 0 10px 5px 0;
  display:block;

  text-align: center;
  border: 1px solid #999999;
  color:#999999;
  padding: 5px 30px;
  transition: border-color .3s, color .3s;

  &.active,
  &:hover{
    color: $primary;
    border-color: $primary;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 0 5px;
    width:100%;
  }
}

.product-search{
  background: #F2F2F2;

  .styled-select {
    position:relative;
    height: 52px;
    width:100%;
    border: 0;
    margin-bottom:20px;

    a{
      color:#000;
    }
  }

  .styled-select .btn-select {
    background: url('../img/dropdown-caret.png') right center no-repeat #E6E6E6;
    border: 1px solid transparent;
    font-size: 16px;
    height: 50px;
    width:100%;
    padding: 5px 50px 5px 20px; /* If you add too much padding here, the options won't show in IE */
    text-align:left;

    &:after{
      display:none;
    }
  }

  .dropdown-menu{
    width:100%;
    background:#f2f2f2;
  }

  .option-color{
    .color-tag{
      width:40px;
      height:30px;
      margin-right:10px;
    }
  }

  .dropdown-placeholder{
    display:block;
    width:100%;
    overflow:hidden;
  }

  .dropdown-checkbox{
    display:block;
    width:10px;
    height:10px;
    background:#e6e6e6;
  }

  .dropdown-item{
    &:active{
      background:inherit;
      color:inherit;
    }
    &.checked{
      .dropdown-checkbox{
        background:$primary;
      }
    }
  }

  .filter-counter {
    margin-left: 3px;
    background: theme-color(primary);
    color: #fff;
    padding: 2px 10px;
    display: inline-block;
    font-weight: bold;
  }
}

.tags{
  .tag{
    color:#5E5E5E;
    display:block;
    background:#CCCCCC;
    transition: color .3s, background .3s;
    margin: 0 10px 10px 0;
    padding:5px 15px;
  }
}


.accordion{
  .card{
    background-color:transparent;
    border-radius: 0;
    border: none;
    border-top:1px solid rgba(0,0,0,.3);

    .card-header{
      background-color:transparent;
      border-radius: 0;
      border: none;
      padding: 0;

      button{
        font-size: 20px;
        font-weight:normal;
        color:#000;
        background-color:transparent;
        border-radius: 0;
        border: none;
        padding: 0;

        &:hover,
        &:active,
        &:visited,
        &:focus
        {text-decoration:none;}

        i.fa-caret-down {display:block}
        i.fa-caret-up   {display:none}

        &[aria-expanded='true']{
          i.fa-caret-down {display:none}
          i.fa-caret-up   {display:block}
        }
      }
    }

    .job-box{
      padding:20px;
      background:rgba(0,0,0,.05);
    }
  }
}

.cookie-banner {
  display:none;
  background: #222;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 8px 10px;
  position: fixed;
  right: 0;
  z-index: 9999;

  p {
    font-size: 16px;
    line-height: 28px;
    margin: 0;
    padding: 0;
  }

  .cookie-text-container{
    flex-grow: 2;

  }

  .cookie-btn {
    font-size: 16px;
    background: #fff;
    border-radius: 3px;
    color: #000;
    float: right;
    margin: 0 5px;
    padding: 5px 10px;
    text-decoration: none;
  }

  .cookie-btn:hover {
    background: #eee;
    color: #000;
    text-decoration: none;
  }

  @include media-breakpoint-down(sm) {
    .cookie-btn-container {
      float: none;
      clear: both;
      width: 100%;
      padding: 10px 0;
    }

    p {
      clear: both;
      width: 100%;
    }
  }
}

.cart-info{
  display:none;
  position:absolute;
  left:50%;
  top:100%;
  transform: translateX(-50%);
  width:300px;
  background:#FFF;
  border:1px solid #e6e6e6;
  z-index:2000;

  a{
    padding:50px 20px;
    &,
    &:hover{
      color:inherit !important;
    }
  }

  &:after{
    content:'';
    position:absolute;
    width:10px;
    height:10px;
    background:#FFF;
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    left:50%;
    top: 0;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  .close-cart-info{
    position:absolute;
    top:0;
    right:0;
    padding: 10px 10px;
    background:#999999;
    color:#FFF;
    cursor:pointer;

    img{
      width:20px;
      height:auto;
    }
  }
}

.header-cart{
  position:relative;
  color:#000;
  @include media-breakpoint-down(md) {
    display:none;
  }
}

.header-cart{
  .cart-info.show{
    display:block;

    @include media-breakpoint-down(md) {
      display:none;
    }
  }
}
.cart-info.cart-info-sm.show{
  display:none;

  @include media-breakpoint-down(md) {
    display:block;
  }
}


.card{
  border-radius:0;
}

.card-header{
  background:#f2f2f2;

  .cart-count-singular{
    display:none;
  }
}

.checkout-form-box{
  background:#f2f2f2;
}

.checkout-list{
  .card-body {
    padding:0;
    .checkout-list-element {
      padding:.75rem 1.25rem;
      border-top: 1px solid rgba(0, 0, 0, .125);
      &:first-child {
        border-top: none;
      }
    }
  }
}

#checkout-list-full{
  .modal-header{
    .close{
      opacity: 1;
      &:hover{
        opacity:.8;
      }
    }
  }

  .checkout-list-full-body{
    padding:0;

    .checkout-list-element {
      padding:.75rem 1.25rem;
      border-top: 1px solid rgba(0, 0, 0, .125);
      &:first-child {
        border-top: none;
      }
    }
  }
}

.cart-add {float:left;}
.header-cart{display:none;}

.checkout-list-empty{
  display:block;
  width:100%;
  padding:.75rem 1.25rem;
  text-align:center;
  font-weight:bold;
  color: rgba(0, 0, 0, .25);
  background:rgba(0, 0, 0, .125);
}

.items-in-cart{
  .header-cart{display:block;}
  .checkout-list-empty{display:none;}
}

.form-control {
  border-radius: 0;
  padding: 10px;
}

.site .site-content select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: auto;
  padding-right: 25px;
}

.form-select-holder {
  position: relative;
  &:after {
    @include pando-caret('bottom', 6px, #ced4da);
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -3px;
    pointer-events: none;
    z-index: 10;
  }
}

.alert {
  border-radius: 0;
}

.custom-input {
  border-radius: .25rem;
}

span.twitter-typeahead .tt-menu .tt-suggestion,
.custom-input.typeahead,
.custom-input.typeahead.tt-input:focus,
.custom-input.typeahead.tt-input.empty{
  color:#888;
  font-weight:400 !important;
}

.tt-highlight,
.custom-input.typeahead.tt-input:focus,
.custom-input.typeahead.tt-input{
  color:#000;
}
